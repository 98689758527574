import React from "react"
import Layout from "../components/layout/"
import Menu07292021Image from "../components/menu07292021Image"

const Contact = () => (
  <Layout>
    <div className="container container-main">
      <h1>Events</h1>
      <div>Check back soon!</div>
    </div>
  </Layout>
)

export default Contact
